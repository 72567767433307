
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
$(document).on("turbolinks:load", function() {

  $('.notify-all').on('click', function() {
    $('.notify-users-all').val(1);
    $('.notify-users-form').submit();
  })

  $('.notify-selected').on('click', function() {
    var values = [];
    $('.users-table .notify-selectbox.selected').each(function() {
      values.push($(this).data('phone')); 
    });

    $('.notify-users-all').val(0);
    $('.notify-users-phones').val(values);
    $('.notify-users-form').submit();
  })

  $('.users-table .notify-selectbox').on('change', function () {
    $(this).toggleClass('selected');
    $('.notify-selected-count').text("(" + $('.users-table .notify-selectbox.selected').length + ")");
  });


});

class User {
  constructor() {
  }
  requestVote(id) {
    var xhr = new XMLHttpRequest();
    xhr.open('PATCH', '/users/mark_presence/' + id.toString(), true);
    xhr.send(new FormData());
  }
}

var user = new User();
export default User; 