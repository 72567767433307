import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'email', 'phone', 'partnerNumber', 'workerNumber', 'workPlace', 'active', 'form', 'method', 'password'];

  connect() {
  }

  async newUser() {
    this.nameTarget.value = "";
    this.emailTarget.value = "";
    this.phoneTarget.value = "";
    this.partnerNumberTarget.value = "";
    this.workerNumberTarget.value = "";
    this.workPlaceTarget.value = "";
    this.activeTarget.checked = true;
    this.formTarget.action = "/users";
    this.methodTarget.value = "post";
    this.passwordTarget.style.display = "none";
    document.getElementById('editUserModalLabel').classList.add('d-none');
    document.getElementById('addUserModalLabel').classList.remove('d-none');
  }
  
  async fetchUser(event) {
    const id = event.currentTarget.dataset.id;
    const response = await fetch('/users/' + id + '/edit');
    const data = await response.json();

    this.nameTarget.value = data.user.name;
    this.emailTarget.value = data.user.email;
    this.phoneTarget.value = data.user.phone;
    this.partnerNumberTarget.value = data.user.partner_number;
    this.workerNumberTarget.value = data.user.worker_number;
    this.workPlaceTarget.value = data.user.work_place;
    this.activeTarget.checked = data.user.active;
    this.formTarget.action = '/users/' + id;
    this.methodTarget.value = "patch";
    this.passwordTarget.style.display = "block";
    document.getElementById('editUserModalLabel').classList.remove('d-none');
    document.getElementById('addUserModalLabel').classList.add('d-none');
  }
}