// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import "bootstrap/dist/js/bootstrap";
window.bootstrap = require("bootstrap/dist/js/bootstrap");
import "@fortawesome/fontawesome-free";

var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import './controllers'
import './confirm.js';
import Speak from './speak.js';
import Vote from './vote.js';
import Doc from './doc.js';
import User from './user.js';
const speak = new Speak();
const vote = new Vote();
const doc = new Doc();
const user = new User();
var table;
var poll_interval;

$(document).on("turbolinks:load", function() {

  $('[data-bs-toggle="tooltip"]').tooltip();
  
  table = $('.data-table').dataTable( {
    "ordering": false,
    "paging": $('.data-table.no-pagination').length == 0
  } );

  $(".nav-link-user").on("click", function(e){
    e.preventDefault();

    // hide components
    $(".sidebar .component").addClass("d-none");
    // show selected component
    $(".sidebar " + $(this).find('.nav-link').attr("href")).removeClass("d-none");
    
    // unselect nav-links
    $(".nav-item.nav-link-user").removeClass("active");
    // select clicked nav-link
    $(this).addClass("active");
    $(this).removeClass("notify");

    $(".zoom-container").addClass("col-8").removeClass("col-12");
    $(".sidebar-container").addClass("col-4").removeClass("col-0");

    openFunction($(this).find('.nav-link').attr("href"));
  });
  
  $(".toggle-side-bar").on("click", function(e){
    $(".zoom-container").toggleClass("col-8 col-12");
    $(".sidebar-container").toggleClass("col-4 col-0");
  });

  $('.admin-form-modal form').on('submit', function(){
    $('.admin-form-modal').modal('hide');
  });

  $('.presence-warning').on('click', function(){ 
    $('body .container-fluid').prepend('<div class="alert alert-danger" role="alert">\
      <span class="close-alert position-absolute fs-4 end-0 me-2" role="button" aria-hidden="true">&times;</span>\
      <strong>Não lhe foi marcada presença!</strong><br>\
      Não poderá pedir para falar nem votar.Por favor entre em contacto com a adminsitração.</div>');
  });
  
  $('.admin-check-box').on('change', function(){
    let $admin_check_container = $(this).parents('.admin-check-container');
    if ($(this).is(":checked")){
      $admin_check_container.find('.yes-label').removeClass('d-none');
      $admin_check_container.find('.no-label').addClass('d-none');
    }
    else{
      $admin_check_container.find('.yes-label').addClass('d-none');
      $admin_check_container.find('.no-label').removeClass('d-none');
    }
  });

  $(".datatable-search").keyup(function() {
    table.api().search( this.value ).draw();
  });

  $(document).on('click', '.close-alert', function(){
    $(".close-alert").parents(".alert").remove();
  });

  setTimeout(()=>{
    $(".alert").remove();
  }, 3000);

  $('.select-vote').on('click', function(){
    vote.openVote($(this).data('vote-id'));
    clearInterval(poll_interval);
    poll_interval = setInterval( ()=>{ vote.openVote($(this).data('vote-id')) }, 10000);
  });
  
  $(document).on('click', '.mark-presence', function(){
    user.requestVote($(this).data('id'));
  });

  var zoom_interval = setInterval(()=>{
    var iframe = document.getElementById('zoom-container');
    var css = '.mini-layout-body-title{  display: none !important;} .mini-layout-body{margin-top: 20px!important;}';
    var style = document.createElement('style');
    style.innerHTML = css;
    if(iframe?.contentWindow?.document.getElementsByClassName('mini-layout-body-title').length > 0){
      iframe.contentDocument.head.appendChild(style);
      clearInterval(zoom_interval);
    }
  }, 500);


});

import { createConsumer } from "@rails/actioncable"
const consumer = createConsumer()
consumer.subscriptions.create({ channel: 'UpdateNotificationsChannel' }, {
  received: function(data) {
    console.log(data);
    // Handle incoming messages here
    if (data.docs){
      doc.reloadDocs();
    }
    else if(data.participant){
      speak.reloadSpeak(JSON.parse(data.participant));
    }
    else if(data.poll){
      vote.reloadVote();
    }
    else if(data.new_participant){
      console.log(data.new_participant);
      
      $('.data-table').dataTable().fnDestroy();
      $('.data-table tbody').append(data.new_participant);
      var table2 = $('.data-table').DataTable( {
        "ordering": false,
        "paging": false
      } );
      //table2.row.add(data.new_participant).draw();
      //table.draw();
    }
    else if(data.current_participantion){ 
      let current_participant = data.current_participantion.current_participant;
      let next_participant = data.current_participantion.next_participant;
      let remaining_participants = data.current_participantion.remaining_participants;
      let remaining_participants_size = data.current_participantion.remaining_participants_size;

      if ($('.current-participant-container').length > 0){
        $('.current-participant .card-title').text(current_participant.user.name + " |  " + current_participant.user.work_place + " |  " + current_participant.user.partner_number);
        $('.current-participant .card-text').text(current_participant.remote ? "Remote" : "Presencial");
        
        if (next_participant){
          $('.next-participant .card-title').text(next_participant.user.name + " |  " + next_participant.user.work_place + " |  " + next_participant.user.partner_number);
          $('.next-participant .card-text').text(next_participant.remote ? "Remote" : "Presencial");
        }

        $('.remaining-participants-size .card-title').text(remaining_participants_size);

        $('.remaining-participants-body').html(remaining_participants);
      }
    }
  }
})

function openFunction(target){
  
  switch (target) {
    case "#speak":
      speak.openSpeak();
      break;
    case "#poll":
      vote.openVote();
      break;
    case "#docs":
      doc.openDocs();
      break;
    default:
      break;
  }
}