
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
$(document).on("turbolinks:load", function() {
  
});
var last_result;

class Doc {
  constructor() {
  }
  openDocs() {
    doc.showLoading();
    const xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
      let result = JSON.parse(this.response);
      if (result.docs){
        // if already has a doc request
        $(".sidebar #docs .docs-container").html(result.docs);
      }
      else{
        // else displays confirmation message
        $(".sidebar #docs .docs-container").html();
      }
      doc.hideLoading();
      last_result = result;
    }
    xhttp.open("GET", "/attachments.json");
    xhttp.send();
  }
  reloadDocs(){
    if ($('.sidebar #docs').hasClass('d-none')){
      $('.navbar .nav-link-user:has(.nav-link[href="#docs"])').addClass('notify');
    }
    else{
      doc.openDocs();
    }
  }
  showLoading() {
    $(".sidebar-loading").removeClass("d-none");
  }
  hideLoading() {
    setTimeout(()=>{
      $(".sidebar-loading").addClass("d-none");
    }, 500);
  }
}

var doc = new Doc();
export default Doc; 