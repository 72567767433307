import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['userId', 'remote', 'form', 'method'];

  connect() {
  }

  async newParticipant() {
    //this.userIdTarget.value = "";
    this.remoteTarget.querySelector('.option-0').selected = true;
    this.formTarget.action = "";
    this.methodTarget.value = "post";

    let select = document.getElementById('search-select');
    let control = select.tomselect;
    control.clear();

    document.getElementById('editParticipantModalLabel').classList.add('d-none');
    document.getElementById('addParticipantModalLabel').classList.remove('d-none');
  }
  
  async fetchParticipant(event) {
    const id = event.currentTarget.dataset.id;
    const response = await fetch('/participants/' + id + '/edit');
    const data = await response.json();

    //this.userIdTarget.value = data.participant.user_id;
    
    let select = document.getElementById('search-select');
    let control = select.tomselect;
    control.addItem(data.participant.user_id);

    //this.remoteTarget.selectedOption = data.participant.remote;
    if (data.participant.remote){
      this.remoteTarget.querySelector('.option-1').selected = true;
    }
    else{
      this.remoteTarget.querySelector('.option-0').selected = true;
    }
    this.formTarget.action = '/participants/' + id;
    this.methodTarget.value = "patch";

    document.getElementById('editParticipantModalLabel').classList.remove('d-none');
    document.getElementById('addParticipantModalLabel').classList.add('d-none');
  }
}