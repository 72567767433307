
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
$(document).on("turbolinks:load", function() {
  
  $('.ask-to-speak-btn').on("click", function(){
    //speak.showLoading();
    $('.ask-to-speak-confirm').removeClass('d-none');
    //speak.hideLoading();
  });
  $('.ask-to-speak-cancel-btn').on("click", function(){
    $('.ask-to-speak-confirm').addClass('d-none');
  });

  $('.ask-to-speak-confirm-btn').on("click", function(){
    speak.requestSpeak();
  });

  $('.turn-to-speak .cancel-request').on("click", function(){
    speak.cancelRequest();
  });

});
var last_result;

class Speak {
  constructor(current, my_turn, speak_id, present) {
    this.current = current;
    this.my_turn = my_turn;
    this.speak_id = speak_id;
    this.present = present;
  }
  openSpeak() {
    speak.showLoading();
    const xhttp = new XMLHttpRequest();
    xhttp.onload = function() {
      let result = JSON.parse(this.response);
      speak = new Speak(result.current, result.my_turn, result.speak_id, result.present);
      if (speak.present && speak.my_turn){
        // if already has a speak request
        speak.displaySpeakOrder();
      }
      else{
        // else displays confirmation message
        $('.ask-to-speak').removeClass('d-none');
        $('.turn-to-speak').addClass('d-none');
        $('.ask-to-speak-confirm').addClass('d-none');

        if (!speak.present){
          $('body .container-fluid').prepend('<div class="alert alert-danger" role="alert">\
            <span class="close-alert position-absolute fs-4 end-0 me-2" role="button" aria-hidden="true">&times;</span>\
            <strong>Não lhe foi marcada presença!</strong><br>\
            Não poderá pedir para falar nem votar.Por favor entre em contacto com a adminsitração.</div>');
        }
      }
      
      speak.hideLoading();
      last_result = result;
    }
    xhttp.open("GET", "/participants.json");
    xhttp.send();
  }
  reloadSpeak(participant) {
    if (speak.my_turn){
      if ($('.sidebar #speak').hasClass('d-none')){
        if (speak.my_turn == participant +1){
          $('.navbar .nav-link-user:has(.nav-link[href="#speak"])').addClass('notify');
        }
      }
      else{
        speak.openSpeak();
      }
    }
  }
  requestSpeak() {
    speak.showLoading();
    var xhr = new XMLHttpRequest();
    xhr.open('POST', '/participants.json', true);
    xhr.onload = function () {
      if (xhr.status == 200){
        let result = JSON.parse(this.response);
        speak = new Speak(result.current, result.my_turn, result.speak_id, result.present);
        speak.displaySpeakOrder();
        speak.hideLoading();
      }
      else{
        $(".container-fluid").prepend(JSON.parse(this.response).message);
        speak.hideLoading();
      }
    };
    xhr.onerror = function () {
      console.log("onerror");
    };
    xhr.send(new FormData());
  }
  displaySpeakOrder() {
    $('.turn-to-speak .speaking-current').val(speak.current);
    $('.turn-to-speak .speaking-my_turn').val(speak.my_turn);

    $('.ask-to-speak').addClass('d-none');
    $('.turn-to-speak').removeClass('d-none');
  }
  cancelRequest() {
    speak.showLoading();
    var xhr = new XMLHttpRequest();
    xhr.open('DELETE', '/participants/' + speak.speak_id + ".json", true);
    xhr.onload = function () {
      speak.openSpeak();
      $('.ask-to-speak-confirm').addClass('d-none');
      speak.hideLoading();
    };
    xhr.send(new FormData());
  }
  showLoading() {
    $(".sidebar-loading").removeClass("d-none");
  }
  hideLoading() {
    setTimeout(()=>{
      $(".sidebar-loading").addClass("d-none");
    }, 500);
  }
}

var speak = new Speak();
export default Speak; 