
var jQuery = require("jquery");
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

$(document).on("turbolinks:load", function() {
  $(document).on('click', '.confirm-trigger-button', function(e){
    e.preventDefault();
    e.stopPropagation();
    $('#confirmationModal .confirmation-text').text($(this).data('text'));
    $('#confirmationModal .confirm-button').attr('href', $(this).data('href'));
    $('#confirmationModal').modal('show');
  });
});