import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['tableIndex', 'title', 'description', 'smsText'];

  connect() {
  }

  async newPoll() {
    console.log(this);
    this.tableIndexTarget.value = "";
    this.titleTarget.value = "";
    this.descriptionTarget.value = "";
    this.smsTextTarget.value = "";

    document.getElementById('editPollModalLabel').classList.add('d-none');
    document.getElementById('addPollModalLabel').classList.remove('d-none');
  }
  
  async fetchPoll(event) {
    let parentRow = event.target.parentNode.closest('.custom-table-row');
    console.log(parentRow);
    this.tableIndexTarget.value = parentRow.querySelector('.option-index').value;
    this.titleTarget.value = parentRow.querySelector('.option-title').value;
    this.descriptionTarget.value = parentRow.querySelector('.option-description').value;
    this.smsTextTarget.value = parentRow.querySelector('.option-sms-text').value;

    document.getElementById('editPollModalLabel').classList.remove('d-none');
    document.getElementById('addPollModalLabel').classList.add('d-none');
  }
}