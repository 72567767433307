import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['title', 'description', 'documentImg', 'document', 'form', 'method'];

  connect() {
  }

  async newAttachment() {
    this.titleTarget.value = "";
    this.descriptionTarget.value = "";
    this.documentImgTarget.src = "";
    this.documentTarget.required = true;
    this.formTarget.action = "";
    this.methodTarget.value = "post";

    document.getElementById('editAttachmentModalLabel').classList.add('d-none');
    document.getElementById('addAttachmentModalLabel').classList.remove('d-none');
    document.getElementById('attachmentImgContainer').classList.add('d-none');
  }
  
  async fetchAttachment(event) {
    const id = event.currentTarget.dataset.id;
    const response = await fetch('/attachments/' + id + '/edit');
    const data = await response.json();

    this.titleTarget.value = data.attachment.title;
    this.descriptionTarget.value = data.attachment.description;
    this.documentImgTarget.src = data.attachment.document_url;
    this.documentTarget.required = false;
    this.formTarget.action = '/attachments/' + id;
    this.methodTarget.value = "patch";

    document.getElementById('editAttachmentModalLabel').classList.remove('d-none');
    document.getElementById('addAttachmentModalLabel').classList.add('d-none');
    document.getElementById('attachmentImgContainer').classList.remove('d-none');
  }
}